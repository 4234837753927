<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item (click)="viewOperationRegion()">
      View Operation Region
    </button>
    <button mat-menu-item (click)="viewLocation()">View Location</button>
    <button
      mat-menu-item
      (click)="changeMapVersion()"
      [disabled]="currentChangeset"
    >
      Change Map Version
    </button>
    <mat-divider />
    <button mat-menu-item (click)="loadChangeset()">Load Changeset</button>
    <button
      mat-menu-item
      (click)="saveChangeset()"
      [disabled]="
        !mapElementManager?.numChanges() ||
        (!!currentChangeset && mapElementManager?.numChanges() === 1)
      "
    >
      Save Changeset
    </button>
    <button
      mat-menu-item
      (click)="rebaseChangeset()"
      [disabled]="!currentChangeset || rebaseMode"
    >
      Rebase Changeset
    </button>
    <button
      mat-menu-item
      (click)="commitChangeset()"
      [disabled]="!currentChangeset || mapElementManager?.numChanges() !== 1"
    >
      Commit Changeset
    </button>
    <button
      mat-menu-item
      (click)="closeChangeset()"
      [disabled]="!currentChangeset"
    >
      Close Changeset
    </button>
    <button
      mat-menu-item
      (click)="deleteChangeset()"
      [disabled]="!currentChangeset"
    >
      Delete Changeset
    </button>

    <mat-divider />
    <button mat-menu-item (click)="modeManager?.setMapEditorMode('routing')">
      Route Testing
    </button>
    <button
      mat-menu-item
      (click)="modeManager?.setMapEditorMode('reachability')"
    >
      Analyze Reachability
    </button>
    <button
      mat-menu-item
      (click)="deployCurrentMapVersion()"
      [disabled]="currentChangeset"
    >
      Deploy Current Map Version
    </button>
    <mat-divider />
    <button mat-menu-item (click)="importMapElements(true)">
      Import As New Map Elements
    </button>
    <button mat-menu-item (click)="importMapElements(false)">
      Import And Update Map Elements
    </button>
    <button mat-menu-item (click)="exportAllCurrentMapElements()">
      Export All Current Map Elements
    </button>
    <button mat-menu-item (click)="exportChangedMapElements()">
      Export Changed Map Elements
    </button>
  </ng-content>
</app-toolbar>

<mat-drawer-container>
  <mat-drawer-content>
    <div #mapContainer id="mapContainer"></div>

    @if (mapElementManager?.loading) {
      <mat-spinner matSuffix [diameter]="200" class="loading-spinner" />
    }

    <div class="map-display-elements-container">
      <div class="map-style-buttons">
        <mat-button-toggle-group
          value="light"
          (change)="setMapStyle($event.value)"
          [hideSingleSelectionIndicator]="true"
          aria-label="Map Style"
          matTooltip="Pick map style"
        >
          <mat-button-toggle value="light">Light</mat-button-toggle>
          <mat-button-toggle value="roadmap">Roadmap</mat-button-toggle>
          <mat-button-toggle value="satellite">Satellite</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-slide-toggle
          (change)="enableAltitudeFlattening(!$event.checked)"
          matTooltip="Show 3D map or squash to 2D"
        >
          <b>3D</b>
        </mat-slide-toggle>
      </div>
      <mat-slider
        [min]="(visualizationManager?.altitudeRange$ | async)?.[0]"
        [max]="(visualizationManager?.altitudeRange$ | async)?.[1]"
        step="0.1"
        discrete
        class="altitude-range-picker"
        matTooltip="Altitude display range"
      >
        <input
          matSliderStartThumb
          [(ngModel)]="minDisplayAltitude"
          (input)="updateDisplayAltitudes()"
        />
        <input
          matSliderEndThumb
          [(ngModel)]="maxDisplayAltitude"
          (input)="updateDisplayAltitudes()"
        />
      </mat-slider>
    </div>

    @if (modeManager?.modeChangesEnabled$ | async) {
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        class="toolbar"
        vertical
        [hideSingleSelectionIndicator]="true"
        [value]="modeManager?.mapEditorMode$ | async"
        (change)="modeManager?.setMapEditorMode($event.value)"
      >
        <mat-button-toggle
          value="select"
          matTooltip="Select and edit existing elements [esc]"
          matTooltipPosition="after"
        >
          <mat-icon>edit</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="delete"
          matTooltip="Delete elements [d]"
          matTooltipPosition="after"
        >
          <mat-icon>delete</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="diff"
          matTooltip="Diff view"
          matTooltipPosition="after"
        >
          <mat-icon>compare</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="createEdge"
          matTooltip="Add edges and nodes [e]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="edge" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createTrafficLight"
          matTooltip="Add traffic lights [t]"
          matTooltipPosition="after"
        >
          <mat-icon>traffic</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          value="createHandoverLocation"
          matTooltip="Add handover location [h]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="handover-location" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createOperationRegion"
          matTooltip="Add operation region [r]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="operation-region" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createAprilTag"
          matTooltip="Add an april tag feature [s]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="april-tag" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createMutex"
          matTooltip="Add a mutex [m]"
          matTooltipPosition="after"
        >
          <mat-icon svgIcon="mutex" />
        </mat-button-toggle>
        <mat-button-toggle
          value="createInfrastructure"
          matTooltip="Add infrastructure"
          matTooltipPosition="after"
        >
          <mat-icon>info_outline</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    }

    <button
      class="visibility-button"
      mat-mini-fab
      color="primary"
      (click)="onVisibilityClick()"
    >
      <mat-icon>visibility</mat-icon>
    </button>

    <img
      class="google-maps-button"
      src="assets/google_maps_icon.svg"
      alt="Google Maps"
      matTooltip="Open location in Google Maps"
      (click)="onGoogleMapsClick()"
    />

    @if (undoRedoAvailable) {
      <button
        class="undo-button"
        mat-mini-fab
        color="primary"
        (click)="mapElementManager?.undoChange()"
        [disabled]="!mapElementManager?.undoChangeAvailable()"
      >
        <mat-icon>undo</mat-icon>
      </button>
    }

    @if (undoRedoAvailable) {
      <button
        class="redo-button"
        mat-mini-fab
        color="primary"
        (click)="mapElementManager?.redoChange()"
        [disabled]="!mapElementManager?.redoChangeAvailable()"
      >
        <mat-icon>redo</mat-icon>
      </button>
    }

    @if (rebaseMode) {
      <div class="rebase-actions-container">
        <div class="rebase-action-buttons">
          <button
            mat-mini-fab
            color="primary"
            (click)="rebaseMode.previousConflict()"
            [disabled]="
              (rebaseMode.previousConflictAvailable$ | async) === false
            "
            matTooltip="Got to previous conflict"
            matTooltipPosition="above"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="rebaseMode.nextConflict()"
            [disabled]="(rebaseMode.nextConflictAvailable$ | async) === false"
            matTooltip="Got to next conflict"
            matTooltipPosition="above"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="exitRebaseMode()"
            matTooltip="Abort rebase"
            matTooltipPosition="above"
          >
            <mat-icon>close</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="saveRebaseToChangesetAndExit()"
            [disabled]="(rebaseMode.allConflictsResolved$ | async) === false"
            matTooltip="Save rebase to changeset and exit"
            matTooltipPosition="above"
          >
            <mat-icon>done</mat-icon>
          </button>
        </div>
        @if (rebaseMode.currentConflict$ | async) {
          <div>
            <mat-button-toggle-group
              [value]="(rebaseMode.currentConflict$ | async)?.choice"
              [hideSingleSelectionIndicator]="true"
              (change)="rebaseMode.resolveCurrentConflict($event.value)"
              class="rebase-choice-buttons"
            >
              <mat-button-toggle
                value="ChangesetVersion"
                matTooltip="Take the current change from the changeset"
                matTooltipPosition="below"
                class="rebase-choice-button"
              >
                Mine
                <div class="choose-mine-circle"></div>
              </mat-button-toggle>
              <mat-button-toggle
                value="LatestVersion"
                matTooltip="Take the latest version from the map history and drop the current change"
                matTooltipPosition="below"
                class="rebase-choice-button"
              >
                Latest
                <div class="choose-latest-circle"></div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
      </div>
    }
  </mat-drawer-content>

  @if (modeManager) {
    <mat-drawer
      mode="side"
      position="end"
      [opened]="
        (selectedMapElement$ | async) !== undefined ||
        (modeManager.mapEditorMode$ | async) === 'routing' ||
        (modeManager.mapEditorMode$ | async) === 'reachability'
      "
      style="padding: 12px; min-width: 280px"
    >
      @if ((modeManager.mapEditorMode$ | async) === 'routing') {
        <mat-card style="margin-bottom: 20px">
          <mat-card-title>Test Routing</mat-card-title>
          <mat-card-content>
            <app-select-operation-button
              [selectedOperationId]="
                modeManager.modes.routing.limitToOperationId
              "
              (selectedOperationChange)="
                modeManager.modes.routing.limitToOperationId = $event?.id
              "
            />
            <div style="display: flex; flex-direction: column">
              <mat-form-field>
                <input #fromLocation matInput placeholder="From (left click)" />
              </mat-form-field>
              <mat-form-field>
                <input #toLocation matInput placeholder="To (right click)" />
              </mat-form-field>
              <span>{{ modeManager.modes.routing.routeInfo }}</span>
            </div>
          </mat-card-content>
        </mat-card>
      }

      @if ((modeManager.mapEditorMode$ | async) === 'reachability') {
        <mat-card>
          <mat-card-title>Reachability</mat-card-title><br />
          <mat-card-content>
            <div style="display: flex; flex-direction: column; width: 200px">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="modeManager.modes.reachability.autonomyOnly"
              >
                Autonomy Only
              </mat-slide-toggle>

              <mat-form-field appearance="fill">
                <mat-label>Max Robot Distance</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="1500"
                  min="1"
                  step="100"
                  [(ngModel)]="modeManager.modes.reachability.maxDistance"
                  style="margin-bottom: 5px"
                />
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      }

      @if (selectedMapElement$ | async; as selectedMapElement) {
        <div>
          <ng-container
            *ngTemplateOutlet="
              propertiesTemplate;
              context: { $implicit: selectedMapElement }
            "
          />
        </div>
      }
    </mat-drawer>
  }
</mat-drawer-container>

<!-- ################# Edge Properties ############################## -->
<ng-template let-edge #edgeProperties>
  <mat-card>
    <mat-card-title>Edge</mat-card-title><br />
    <mat-card-subtitle
      >id: {{ edge.id }} &nbsp; length:
      {{ edge.properties.length | number: '1.1-1' }}m &nbsp; nodes:
      {{ edge.properties.startNodeId }}:{{
        edge.properties.endNodeId
      }}</mat-card-subtitle
    >
    <mat-card-content>
      <div class="edge-options">
        <mat-form-field appearance="fill">
          <mat-label>Edge Type</mat-label>
          <mat-select
            [(ngModel)]="edge.elementType"
            (selectionChange)="addChange(edge)"
            [disabled]="edge.elementType === 'CachedRoadEdge'"
          >
            @for (edgeType of edgeTypes; track edgeType) {
              <mat-option
                [value]="edgeType.elementType"
                [disabled]="!edgeType.selectable"
              >
                {{ edgeType.displayName }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <br />
        @if (edge.properties.estimatedDuration) {
          <span class="mat-body"
            >Estimated Duration:
            {{ edge.properties.estimatedDuration / 60 | number: '1.1-1' }}
            minutes</span
          >
          <br />
        }

        @if (
          [
            'RobotEdge',
            'InfrastructureEdge',
            'InfrastructureWaitingEdge',
          ].includes(edge.elementType)
        ) {
          <mat-slide-toggle
            [(ngModel)]="edge.properties.allowUnsupervisedAutonomy"
            (change)="addChange(edge)"
          >
            Allow Unsupervised Autonomy
          </mat-slide-toggle>

          <br />

          <mat-slide-toggle
            [checked]="!!edge.properties.blockedAt"
            (change)="updateBlockage(edge, $event.checked)"
          >
            Blocked
            @if (edge.properties.blockedAt) {
              <span> at {{ edge.properties.blockedAt | date: 'short' }}</span>
            }
          </mat-slide-toggle>
          <br />
          <div>
            @if (edge.properties.blockedUntil) {
              <span>
                Blocked until
                {{ edge.properties.blockedUntil | date: 'short' }}</span
              >
            }
            @if (!edge.properties.blockedUntil) {
              <span> Block for </span>
            }
          </div>

          <div class="blocked-until-input">
            <mat-form-field appearance="fill">
              <mat-label>Months</mat-label>
              <input
                matInput
                type="number"
                placeholder="1"
                min="0"
                max="2000"
                step="1"
                [(ngModel)]="blockedFor.months"
                (change)="updateBlockedUntil(edge)"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Days</mat-label>
              <input
                matInput
                type="number"
                placeholder="1"
                min="0"
                max="2000"
                step="1"
                [(ngModel)]="blockedFor.days"
                (change)="updateBlockedUntil(edge)"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Hours</mat-label>
              <input
                matInput
                type="number"
                placeholder="1"
                min="0"
                max="2000"
                step="1"
                [(ngModel)]="blockedFor.hours"
                (change)="updateBlockedUntil(edge)"
              />
            </mat-form-field>
          </div>
          <br />

          <mat-form-field appearance="fill">
            <mat-label>Slowdown Factor</mat-label>
            <input
              matInput
              type="number"
              placeholder="1"
              min="1"
              [(ngModel)]="edge.properties.slowDownFactor"
              (change)="addChange(edge)"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Max Speed</mat-label>
            <input
              matInput
              type="number"
              placeholder="1.7"
              min="0.1"
              max="2.5"
              step="0.1"
              [(ngModel)]="edge.properties.maxSpeed"
              (change)="addChange(edge)"
            />
          </mat-form-field>

          <mat-slide-toggle
            [(ngModel)]="edge.properties.requiresHazardLights"
            (change)="addChange(edge)"
          >
            Requires Hazard Lights
          </mat-slide-toggle>

          <br />
          <mat-slide-toggle
            [(ngModel)]="edge.properties.oneway"
            (change)="addChange(edge)"
          >
            Oneway
          </mat-slide-toggle>
          <br />
          <mat-form-field appearance="fill">
            <mat-label>Start Width</mat-label>
            <input
              matInput
              type="number"
              placeholder="1"
              min="0.5"
              step="0.1"
              [(ngModel)]="edge.properties.startWidth"
              (input)="addChange(edge)"
            />
          </mat-form-field>
          <br />
          <mat-form-field appearance="fill">
            <mat-label>End Width</mat-label>
            <input
              matInput
              type="number"
              placeholder="1"
              min="0.5"
              step="0.1"
              [(ngModel)]="edge.properties.endWidth"
              (input)="addChange(edge)"
            />
          </mat-form-field>
          <br />

          <div>
            <mat-slide-toggle
              [checked]="!!edge.properties.crossing"
              (change)="
                edge.properties.crossing = $event.checked
                  ? { crosses: 'Driveway' }
                  : undefined;
                addChange(edge)
              "
            >
              Crossing
            </mat-slide-toggle>
            <br />
            <br />
          </div>

          @if (edge.properties.crossing) {
            <mat-form-field appearance="fill">
              <mat-label>Crossing Type</mat-label>
              <mat-select
                placeholder="No crossing"
                [(ngModel)]="edge.properties.crossing.crosses"
                (selectionChange)="addChange(edge)"
              >
                @for (crossingType of crossingTypes; track crossingType) {
                  <mat-option [value]="crossingType.value">
                    {{ crossingType.displayName }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <br />
          <br />
          @if (edge.properties.crossing) {
            <div>
              <mat-slide-toggle
                [(ngModel)]="edge.properties.crossing.buttonPressRequired"
                (change)="addChange(edge)"
              >
                Button Press Required
              </mat-slide-toggle>
              <br />
              <br />
            </div>
          }

          <mat-form-field appearance="fill">
            <mat-label>Mutex Ids</mat-label>
            <mat-select
              [(ngModel)]="edge.properties.mutexIds"
              (selectionChange)="addChange(edge)"
              multiple
            >
              @for (mutex of mapElementManager?.mutexes$ | async; track mutex) {
                <mat-option [value]="mutex.id">
                  {{ mutex.id }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        @if (
          ['InfrastructureEdge', 'InfrastructureWaitingEdge'].includes(
            edge.elementType
          )
        ) {
          <br />
          <!--mat-form-field appearance="fill">
            <mat-label>Infrastructure ID</mat-label>
            <input
              matInput
              type="number"
              placeholder=""
              step="1"
              [(ngModel)]="edge.properties.infrastructureId"
              (input)="addChange(edge)"
            />
          </mat-form-field-->

          <mat-form-field appearance="fill">
            <mat-label>Infrastructure ID</mat-label>
            <mat-select
              [(ngModel)]="edge.properties.infrastructureId"
              (selectionChange)="addChange(edge)"
            >
              @for (
                infrastructure of mapElementManager?.infrastructure$ | async;
                track infrastructure
              ) {
                <mat-option [value]="infrastructure.id">
                  {{ infrastructure.properties.infrastructureType }}:
                  {{ infrastructure.id }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <br />
          <mat-form-field appearance="fill">
            <mat-label>Infrastructure Location Name</mat-label>
            <input
              matInput
              type="text"
              placeholder=""
              [(ngModel)]="edge.properties.infrastructureLocationName"
              (input)="addChange(edge)"
            />
          </mat-form-field>
        }

        @if (edge.elementType === 'RobotQueueEdge') {
          <div>
            <h3>Slot Priorities</h3>
            <button
              mat-mini-fab
              color="primary"
              (click)="
                edge.properties.queueSlotPriorities =
                  edge.properties.queueSlotPriorities || [];
                edge.properties.queueSlotPriorities.push(
                  edge.properties.queueSlotPriorities.slice(-1)[0] || 0
                );
                addChange(edge)
              "
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ol>
            @for (
              priority of edge.properties.queueSlotPriorities;
              track trackSlotPrioritiesByIndex(i, priority);
              let i = $index
            ) {
              <li>
                <!-- FIXME: enforce sorted -->
                <div class="robot-queue-edge-display-names-list">
                  <mat-form-field appearance="fill">
                    <mat-label>Add Slot Priority</mat-label>
                    <input
                      name="a{{ i }}"
                      matInput
                      type="number"
                      placeholder="0"
                      min="0"
                      [(ngModel)]="edge.properties.queueSlotPriorities[i]"
                      (change)="addChange(edge)"
                    />
                  </mat-form-field>
                  @if (i > 0) {
                    <button
                      mat-mini-fab
                      color="primary"
                      (click)="
                        edge.properties.queueSlotPriorities.splice(i, 1);
                        addChange(edge)
                      "
                    >
                      <mat-icon>remove</mat-icon>
                    </button>
                  }
                </div>
              </li>
            }
          </ol>

          <br />
          <div>
            <h3>Names</h3>
            <input type="text" [(ngModel)]="robotQueueEdgeNameTextInput" />
            <button
              mat-mini-fab
              color="primary"
              (click)="
                edge.properties.names = edge.properties.names || [];
                edge.properties.names.push(robotQueueEdgeNameTextInput);
                addChange(edge)
              "
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <ul style="list-style-type: none">
            @for (name of edge.properties.names; track name; let i = $index) {
              <li>
                <div class="robot-queue-edge-display-names-list">
                  <label>{{ name }}</label>
                  <button
                    mat-mini-fab
                    color="primary"
                    (click)="
                      edge.properties.names.splice(i, 1); addChange(edge)
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
              </li>
            }
          </ul>

          <br />
          <div>
            <h3>Display Names</h3>
            <input
              type="text"
              [(ngModel)]="robotQueueEdgeDisplayNameTextInput"
            />
            <button
              mat-mini-fab
              color="primary"
              (click)="
                edge.properties.displayNames =
                  edge.properties.displayNames || [];
                edge.properties.displayNames.push(
                  robotQueueEdgeDisplayNameTextInput
                );
                addChange(edge)
              "
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <ul style="list-style-type: none">
            @for (
              displayName of edge.properties.displayNames;
              track displayName;
              let i = $index
            ) {
              <li>
                <div class="robot-queue-edge-display-names-list">
                  <label>{{ displayName }}</label>
                  <button
                    mat-mini-fab
                    color="primary"
                    (click)="
                      edge.properties.displayNames.splice(i, 1); addChange(edge)
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>
              </li>
            }
          </ul>
        }
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# Operation Region Properties ############################## -->
<ng-template let-operationRegion #operationRegionProperties>
  <mat-card>
    <mat-card-title>Operation Region</mat-card-title><br />
    <mat-card-subtitle
      >id: {{ operationRegion.id }}<br />
      area [sq m]:
      {{ getRegionPolygonArea(operationRegion.geometry) | number: '1.0-0' }}
    </mat-card-subtitle>
    <mat-card-content>
      <app-select-operation-button
        [selectedOperationId]="operationRegion.properties.operationId"
        (selectedOperationChange)="
          operationRegion.properties.operationId = $event?.id;
          addChange(operationRegion)
        "
      >
      </app-select-operation-button>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# April Tag Properties ############################## -->
<ng-template let-apriltag #aprilTagProperties>
  <mat-card>
    <mat-card-title>April-Tag</mat-card-title><br />
    <mat-card-subtitle>id: {{ apriltag.id }} </mat-card-subtitle>
    <mat-card-content>
      <mat-divider style="margin-bottom: 16px" />
      <mat-form-field appearance="fill">
        <mat-label>Tag Id</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          min="0"
          step="1"
          [(ngModel)]="apriltag.properties.id"
          (change)="addChange(apriltag)"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# Infrastructure Properties ############################## -->
<ng-template let-infrastructure #infrastructureProperties>
  <mat-card>
    <mat-card-title>Infrastructure</mat-card-title><br />
    <mat-card-subtitle>id: {{ infrastructure.id }} </mat-card-subtitle>
    <mat-card-content>
      <mat-divider style="margin-bottom: 16px" />
      <mat-form-field appearance="fill">
        <mat-label>External ID</mat-label>
        <input
          matInput
          type="text"
          placeholder=""
          [(ngModel)]="infrastructure.properties.externalInfrastructureId"
          (change)="addChange(infrastructure)"
        />
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Infrastructure Type</mat-label>
        <mat-select
          [(ngModel)]="infrastructure.properties.infrastructureType"
          (selectionChange)="addChange(infrastructure)"
        >
          @for (
            infrastructureType of infrastructureTypes;
            track infrastructureType
          ) {
            <mat-option [value]="infrastructureType">
              {{ infrastructureType }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Parent Infrastructure ID</mat-label>
        <mat-select
          [(ngModel)]="infrastructure.properties.parentInfrastructureId"
          (selectionChange)="addChange(infrastructure)"
        >
          <mat-option [value]=""> - </mat-option>

          @for (
            infra of mapElementManager?.infrastructure$ | async;
            track infra
          ) {
            @if (infra.id !== infrastructure.id) {
              <mat-option [value]="infra.id">
                {{ infra.properties.infrastructureType }}:
                {{ infra.id }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <div class="custom-fields">
        @for (
          item of infrastructure.properties.customFields | keyvalue;
          track item.key
        ) {
          <div class="custom-field-item">
            <span class="custom-field-key">{{ item.key }}</span
            >: <span class="custom-field-value">{{ item.value }}</span>
            <span class="custom-filed-delete-button">
              <mat-icon
                (click)="deleteCustomFieldItem(infrastructure, $any(item.key))"
                >delete</mat-icon
              >
            </span>
          </div>
        }
        <button
          mat-mini-fab
          color="primary"
          (click)="addCustomFieldItem(infrastructure)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# Pole Properties ############################## -->
<ng-template let-pole #poleProperties>
  <mat-card>
    <mat-card-title>Pole</mat-card-title><br />
    <mat-card-subtitle>id: {{ pole.id }} </mat-card-subtitle>
    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Radius</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          min="0"
          step="0.1"
          [(ngModel)]="pole.properties.radius"
          (change)="addChange(pole)"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# Handover Location Properties ############################## -->
<ng-template let-handover #handoverLocationProperties>
  <mat-card>
    <mat-card-title>Handover Location</mat-card-title><br />
    <mat-card-subtitle>id: {{ handover.id }} </mat-card-subtitle>
    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Lookup Address</mat-label>
        <input
          #address
          matInput
          type="string"
          placeholder="400 Main Street #200, New York"
          (keyup.enter)="lookupHandoverAddress(address.value, handover)"
        />
        @if (lookingUpAddress) {
          <mat-spinner
            matSuffix
            [diameter]="18"
            style="float: right; margin-left: 8px"
          />
        }
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Street Name</mat-label>
        <input
          matInput
          readonly
          [value]="handover.properties.streetName || ''"
        />
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Street Number</mat-label>
        <input
          matInput
          readonly
          [value]="handover.properties.streetNumber || ''"
        />
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Unit/Suite/Apt</mat-label>
        <input
          matInput
          readonly
          [value]="handover.properties.subpremise || ''"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- ################# Default Properties ############################## -->
<ng-template let-mapElement #defaultProperties>
  <div style="margin-bottom: 16px">
    <mat-card-title>{{ mapElement.elementType }}</mat-card-title
    ><br />
    <mat-card-subtitle>id: {{ mapElement.id }}</mat-card-subtitle>
  </div>
</ng-template>

<!-- ################# No Properties ############################## -->
<ng-template #noProperties> </ng-template>
