import {
  ClickEvent,
  ModeProps,
  GuideFeatureCollection,
  TentativeFeature,
  EditHandleFeature,
} from '../visualization/types';
import { FeatureOf, Polygon } from '../visualization/geojson-types';
import { getPickedEditHandle } from '../visualization/utils';
import { InteractiveMode } from '../visualization/interactive-mode';
import { lineStringSelfOverlaps } from './geometry-utils';
import { rewindPolygon } from './geometry-manipulation-utils';
import { GeoPoint } from '@cartken/map-types';
import {
  hasAtLeastThreeElements,
  hasAtLeastTwoElements,
} from '@/utils/typeGuards';

export class DrawPolygonMode extends InteractiveMode {
  polygonPoints: GeoPoint[] = [];

  override setActive(active: boolean) {
    if (!active) {
      this.reset();
    }
  }

  private reset() {
    this.polygonPoints = [];
  }

  override createTentativeFeature(
    props: ModeProps,
  ): TentativeFeature | undefined {
    const { lastHoverEvent } = props;

    const lastCoords = lastHoverEvent ? [lastHoverEvent.mapCoords] : [];
    const coordinates = [...this.polygonPoints, ...lastCoords];

    if (hasAtLeastThreeElements(this.polygonPoints)) {
      return {
        type: 'Feature',
        properties: {
          guideType: 'tentative',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [...this.polygonPoints, ...lastCoords, this.polygonPoints[0]],
          ],
        },
      };
    }

    if (hasAtLeastTwoElements(coordinates)) {
      return {
        type: 'Feature',
        properties: {
          guideType: 'tentative',
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      };
    }

    return undefined;
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const guides: GuideFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const tentativeFeature = this.createTentativeFeature(props);
    if (tentativeFeature) {
      guides.features.push(tentativeFeature);
    }

    const editHandles = this.polygonPoints.map(
      (coord, index) =>
        ({
          type: 'Feature',
          properties: {
            guideType: 'editHandle',
            editHandleType: 'existing',
            featureIndex: -1,
            positionIndexes: [index],
          },
          geometry: {
            type: 'Point',
            coordinates: coord,
          },
        }) as EditHandleFeature,
    );

    guides.features.push(...editHandles);
    return guides;
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const { picks } = event;
    const clickedEditHandle = getPickedEditHandle(picks);
    const selfOverlaps = lineStringSelfOverlaps([
      ...this.polygonPoints,
      event.mapCoords,
    ]);

    let positionAdded = false;
    if (!clickedEditHandle && !selfOverlaps) {
      // Don't add another point right next to an existing one
      this.polygonPoints.push(event.mapCoords);
      positionAdded = true;
    }

    if (
      this.polygonPoints.length > 2 &&
      clickedEditHandle &&
      Array.isArray(clickedEditHandle.properties.positionIndexes) &&
      (clickedEditHandle.properties.positionIndexes[0] === 0 ||
        clickedEditHandle.properties.positionIndexes[0] ===
          this.polygonPoints.length - 1)
    ) {
      // They clicked the first or last point (or double-clicked), so complete the polygon
      this.finishPolygon(props);
    } else if (positionAdded) {
      // new tentative point
      props.onEdit({
        // data is the same
        updatedData: props.data,
        editType: 'addTentativePosition',
        editContext: {
          position: event.mapCoords,
        },
      });
    }
  }

  override onKeyUp(event: KeyboardEvent, props: ModeProps) {
    if (event.key === 'Enter') {
      this.finishPolygon(props);
    }
  }

  override onRightClick(event: ClickEvent, props: ModeProps) {
    this.finishPolygon(props);
  }

  private finishPolygon(props: ModeProps) {
    if (!hasAtLeastThreeElements(this.polygonPoints)) {
      return;
    }

    const polygonGeometry: Polygon = {
      type: 'Polygon',
      coordinates: [[...this.polygonPoints, this.polygonPoints[0]]],
    };

    if (lineStringSelfOverlaps(polygonGeometry.coordinates[0].slice(1))) {
      return;
    }

    this.reset();

    const feature: FeatureOf<Polygon> = {
      type: 'Feature',
      properties: {},
      geometry: polygonGeometry,
    };

    const rewindFeature = rewindPolygon(feature);
    this.addPolygon(rewindFeature);
  }

  protected addPolygon(polygon: FeatureOf<Polygon>) {
    // pass
  }
}
