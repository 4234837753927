import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import { DisplayableRoute, routes } from '../app-routing.module';
import { User } from '../core/user';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';
import { AsyncPipe } from '@angular/common';
import { MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCard, MatCardContent } from '@angular/material/card';
import { KeycloakService } from 'keycloak-angular';

export interface SiteSwitch {
  name: string;
  url: string;
}

function getAccessibleRoutes(
  routes: DisplayableRoute[],
  user?: User,
): DisplayableRoute[] {
  return routes.filter((route) => {
    if (route.displayName === undefined) {
      return false;
    }
    return route.data?.allowedRoles.some(
      (allowedRole) => user?.roles.includes(allowedRole) || allowedRole == '*',
    );
  });
}

function getAccessibleRoutesFromRoles(
  routes: DisplayableRoute[],
  roles: string[],
): DisplayableRoute[] {
  return routes.filter((route) => {
    if (route.displayName === undefined) {
      return false;
    }
    return route.data?.allowedRoles.some(
      (allowedRole) => roles.includes(allowedRole) || allowedRole == '*',
    );
  });
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    MatButton,
    MatIcon,
    MatCard,
    MatCardContent,
    AsyncPipe,
  ],
})
export class LandingPageComponent {
  switchSiteName = environment.siteSwitch.name;

  accessibleRoutes = new ReplaySubject<DisplayableRoute[]>(1);

  constructor(
    public auth: AuthService,
    private router: Router,
    public keycloakService: KeycloakService,
  ) {
    if (this.keycloakService.isLoggedIn()) {
      try {
        Promise.resolve(keycloakService.getUserRoles()).then((roles) => {
          this.accessibleRoutes.next(
            getAccessibleRoutesFromRoles(routes, roles),
          );
          /*
          // TODO: Is user.landingPage still needed?
          if (user && user.landingPage) {
            this.router.navigate([user.landingPage]);
          }
          */
        });
      } catch (error) {
        console.error('failed to load user profile:', error);
      }
    } else {
      this.auth.user$.subscribe((user) => {
        this.accessibleRoutes.next(getAccessibleRoutes(routes, user));
        if (user && user.landingPage) {
          this.router.navigate([user.landingPage]);
        }
      });
    }
  }

  switchSite() {
    window.location.href = environment.siteSwitch.url;
  }
}
