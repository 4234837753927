/**
 * Removes duplicates from `items` based on `key` identification function.
 */
export function distinctBy<T>(items: Iterable<T>, key: (item: T) => unknown) {
  const existing = new Set();
  const result = [];
  for (const item of items) {
    const key_ = key(item);
    if (existing.has(key_)) {
      continue;
    }
    result.push(item);
    existing.add(key_);
  }
  return result;
}
