import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthService } from './auth.service';
import { firstValueFrom, Observable } from 'rxjs';
import { tap, filter, map, switchMap, take } from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private keycloakService: KeycloakService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map((user) => {
        const allowedRoles: string[] = route.data['allowedRoles'] || [];
        console.log("canActivate");
        // await firstValueFrom(this.keycloakService.keycloakEvents$.pipe(tap((e) =>{console.log(e)}), filter((e) => e.type === KeycloakEventType.OnReady)))
        if (!user && !this.keycloakService.isLoggedIn()) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url, requiredRoles: allowedRoles },
          });
          return false;
        }

        var hasAccess = false;
        if (this.keycloakService.isLoggedIn()) {
          hasAccess = allowedRoles.some(
            (allowedRole) =>
              this.keycloakService.isUserInRole(allowedRole) ||
              allowedRole == '*',
          );
        } else if (user) {
          hasAccess = allowedRoles.some(
            (allowedRole) =>
              user.roles.includes(allowedRole) || allowedRole == '*',
          );
        }

        if (!hasAccess) {
          this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url, requiredRoles: allowedRoles },
          });
          return false;
        }
        return true;
      }),
    );
  }
}
