export const environment = {
  production: true,
  name: 'staging',
  backendUrl: 'https://staging-ops.cartken.com/api',
  mlBackendUrl: 'https://staging-ops.cartken.com/ml/api',
  socketUrl: 'wss://staging-ops.cartken.com/api',
  googleMapsApiKey: 'AIzaSyDFLb7J19fsCdlMEErap2EGAC32ZgIzqWw',
  mapboxApiKey:
    'pk.eyJ1Ijoiam9uYXMtd2l0dCIsImEiOiJjazJhN2FmYWMycWprM25ucnIyZ3FraWg2In0.oXIOU0KMN4yLWANhXodltg',
  siteSwitch: {
    name: 'Production',
    url: 'https://ops.cartken.com',
  },
  sentry: {
    dsn: 'https://1d294c4164714b6aa072bcbbd806405e@o4505049490194432.ingest.sentry.io/4505049522044928', // TODO: Populate this from .env files
    tunnel: undefined, // Use sentry.io directly
    enabled: true,
    errors: true,
    tracing: 0,
    replay: false,
    replaySessions: 0.05,
    replayErrors: 0.25,
  },
  keycloak_realm: 'cartken',
  // keycloak_uri: 'https://staging-auth.cartken.com',
  keycloak_uri: 'https://keycloak-243170214252.europe-west3.run.app'
};
